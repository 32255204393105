


























import { getCategory, ICategoryInfo } from '@/api/product';
import { Vue, Component } from 'vue-property-decorator';
import HeaderSearch from '@/components/Base/HeaderSearch/index.vue';
@Component({
  name: '',
  components: { HeaderSearch }
})
export default class extends Vue {
  categoryList: ICategoryInfo[] = [];
  categoryCatId = NaN;
  sidebarActive = 0;

  async getCategoryList() {
    const { payload } = await getCategory();
    this.categoryList = payload;
  }

  get categoryChildren() {
    return this.categoryList.length ? this.categoryList[this.sidebarActive].children : [];
  }

  handleClickCategoryContent(item: ICategoryInfo) {
    this.$router.push({ name: 'Search', query: { catId: String(item.catId), name: item.name } });
  }

  created() {
    this.getCategoryList();
  }
}
